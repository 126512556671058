import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import Layout from '~/components/Layout'
import { IPost, PostsForIndexPageQuery, IGasbyPageProps } from '~/types'
import SEO from '~/components/SEO'
import PostPreview from '~/components/PostPreview'
import Head from '~/components/Head'
import ArticleContainer from '~/components/ArticleContainer'
import AnnounceView from '~/components/AnnounceView'
import { formatPostListFromData, formatPostFromData } from '~/utils/post'
import {primaryColor, headerFontFamilyString, linesRem, contentWidth } from '~/utils/styling'
import Slider from '~/components/Slider'

interface IIndexPageProps extends IGasbyPageProps {
  data: PostsForIndexPageQuery
}

const ButtonLink = styled(Link)`
  font-family: ${headerFontFamilyString};
  background: ${primaryColor};
  font-size: 20px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.85);
  padding: 10px 20px;
  margin-right: 10px;
  &:link {
    color: rgba(255, 255, 255, 0.85);
  }
  &:visited {
    color: rgba(255, 255, 255, 0.85);
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`

const Subheading = styled.h3`
  color: #999;
  text-transform: uppercase;
  font-size: 20px;
`

const AnnounceContainer = styled.div`
  /* background: rgba(117, 37, 195, 0.1); */
  box-sizing: border-box;
  margin-bottom: 50px;
  /* overflow: hidden; */
  /* box-shadow: inset 0 5px 20px rgba(45, 12, 63, .2); */
  position: relative;
  @media (min-width: ${contentWidth}px) {
    /* background: rgba(117, 37, 195, 0.1); */
    > .slider {
      border-radius: 8px;
      box-shadow: 0 4px 14px -2px rgba(45, 12, 63, .5);
    }
  }
`

const IndexPage: React.FC<IIndexPageProps> = ({ data, uri, pageContext }) => {
  const posts = formatPostListFromData(data)

  // const postsPerPage = data.site.siteMetadata.postsPerPage
  // const numPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)

  const [announces, setAnnounces] = useState(
    data.announces.edges
      .map((edge: any, index: any) => {
        const res = {
          ...formatPostFromData(edge.node, index),
          end: new Date(edge.node.frontmatter.end),
        }
        return res
      })
      .filter((announce:any) => {
        return announce.end.valueOf() > Date.now()
      })
  )

  useEffect(() => {
    if (announces.length === 0) return
    const intervalId = setInterval(() => {
      const newValue = announces.filter((announce: any) => {
        return announce.end.valueOf() > Date.now()
      })
      if (announces.length > newValue.length) {
        setAnnounces(newValue)
        if (newValue.length === 0) {
          clearInterval(intervalId)
        }
      }
    }, 60000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const announceSubheadingJsx = <div className="fixed-width mt-2 mb-2">
    <Subheading>Анонсы ближайших событий</Subheading>
  </div>

  const announceJsx = 
    <AnnounceContainer>
      <Slider className="fixed-width slider">
        {announces.map((announce: IPost, index: number) => (
          <AnnounceView post={announce} key={index} />
        ))}
      </Slider>
    </AnnounceContainer>

  const showAnnounceBlocks = announces && announces.length > 0

  return (
    <Layout uri={uri}>
      <Head></Head>
      {showAnnounceBlocks && announceSubheadingJsx}
      {showAnnounceBlocks && announceJsx}
      <div className="fixed-width mt-4">
        <Subheading>Последнее в блоге</Subheading>
      </div>
      <ArticleContainer>
        {posts.map((post, index) => (
          <PostPreview post={post} key={index} />
        ))}
        <div
          className="fixed-width"
          style={{
            textAlign: `center`,
          }}
        >
          <ButtonLink to="/blog/page-2">
            Читать остальные публикации →
          </ButtonLink>
        </div>
      </ArticleContainer>
      <SEO
        meta={[
          {
            property: `og:image`,
            content: data.file.childImageSharp.fixed.src,
          },
          {
            property: `og:image:width`,
            content: `1920`,
          },
          {
            property: `og:image:height`,
            content: `1080`,
          },
          {
            property: `og:url`,
            content: `${data.site.siteMetadata.siteUrl}`,
          },
        ]}
      ></SEO>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query postsForIndexPage ($contentWidth: Int!) {
  site {
    siteMetadata {
      postsPerPage
    }
  }
  allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    limit: 10
    filter: {
      frontmatter: {draft: {ne: true}}
      fields: {
        urlPath: {ne: null}
        source: {eq: "posts"}
      }
    }
  ) {
    totalCount
    edges {
      node {
        ...PostDataFragment
      }
    }
  }
  announces: allMarkdownRemark(
    sort: {fields: frontmatter___date, order: ASC}
    filter: {
      frontmatter: {draft: {ne: true}}
      fields: {
        urlPath: {ne: null}
        source: {eq: "announces"}
      }
    }
  ) {
    edges {
      node {
        html
        frontmatter {
          title
          urlKey
          type
          desc
          youtube
          rutube
          vk
          date(formatString: "DD MMMM, YYYY", locale: "ru")
          end
          images {
            childImageSharp {
              fluid(maxWidth: $contentWidth) {
                ...GatsbyImageSharpFluid
              }
              original {
                height
                width
              }
            }
          }
          cover {
            childImageSharp {
              fluid(maxWidth: $contentWidth, quality: 70) {
                ...GatsbyImageSharpFluid
              }
              original {
                height
                width
              }
            }
          }
          imageOptions {
            objectFit
            maxHeight
          }
        }
        fields {
          urlPath
          dateValue
          renderer
        }
      }
    }
  }
  file(relativePath: { eq: "site-cover.jpg" }) {
    childImageSharp {
      fixed(width: 1920, height: 1080, quality: 85) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}`